














































































import { Component, Ref, Vue } from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import {
  AttachmentHostType,
  AuditFlowScope,
  CustomFormType,
  OrganizationUnitDto,
  ProjectBeneficiaryMappingDto,
  ProjectServiceItemDto,
  ProjectSpecificGoalDto,
  ServiceRecordType,
} from "@/api/appService";
import api from "@/api/index";

import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, { AuditApi } from "@/components/Audit/AuditBlock.vue";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";

@Component({
  name: "ServiceRecordDetail",
  components: {
    CustomFormDataTableDetail,
    ExportWord,
    AttachmentsView,
    AuditBlock,
    FileList,
  },
  filters: {
    formatCompleteStatus(status: string, completeStatusList: any[]) {
      let value: string | undefined;
      if (completeStatusList && completeStatusList.length > 0) {
        completeStatusList.map((item: any) => {
          if (item.value == status) {
            value = item.text;
            return;
          }
        });
      }
      return value;
    },
  },
})
export default class ServiceRecordDetail extends Vue {
  type: ServiceRecordType = ServiceRecordType.School;
  private types = ServiceRecordType;
  private formId!: string;
  private dataId!: string;
  private hostType: CustomFormType = CustomFormType.BeneficiaryServiceRecord;
  private isProject = false;
  isReady = false;
  serviceItems: ProjectServiceItemDto[] = [];
  beneficiary: ProjectBeneficiaryMappingDto = {};
  organization: OrganizationUnitDto = {};
  // 达成情况
  completeStatusList = [];

  styles = "";
  selector = "service-record-detail";
  filename = "服务记录表";
  get hostTypeFile() {
    return AttachmentHostType.Internal_ServiceRecord;
  }
  created() {
    this.fetchEnum();
    this.formId = this.$route.query.formId as string;
    this.dataId = this.$route.query.id as string;
    this.isProject = false;

    if (this.dataId && this.hostType) {
      this.isReady = true;
    } else {
      this.$message.error("缺少url参数 id 或 hostType，请检查!");
    }

    this.fetchAuditDetail();
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectServiceRecordCompleteStatus" })
      .then((res: any) => {
        this.completeStatusList = res;
      });
  }

  fetchAuditDetail() {
    api.beneficiaryServiceRecord
      .get({ id: parseInt(this.dataId) })
      .then((res) => {
        this.type = this.$route.query.type as ServiceRecordType;
        if (this.type === ServiceRecordType.School) {
          this.hostType = CustomFormType.SchoolServiceRecord;

          api.organizationUnit.get({ id: res.schoolId }).then((res) => {
            this.organization = res;
          });
        } else {
          this.hostType = CustomFormType.BeneficiaryServiceRecord;

          api.beneficiary.get({ id: res.beneficiaryId }).then((res) => {
            this.beneficiary = res;
          });
        }

        this.serviceItems = res.serviceItems as ProjectServiceItemDto[];
        if (this.serviceItems) {
          this.serviceItems.map((item) => {
            item.serviceMethod = item.serviceMethods?.join(",");
          });
        }
      });
  }

  getData() {
    return api.beneficiaryServiceRecord.get({ id: parseInt(this.dataId) });
  }

  get auditApi() {
    return {
      canAudit: api.beneficiaryServiceRecord.canAudit,
      userAudit: api.beneficiaryServiceRecord.audit,
    } as AuditApi;
  }

  getHostType() {
    return this.hostType;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.beneficiaryServiceRecord.audit;
  }
}
