
























































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  AuditFlowScope,
  // AuditUserLogType,
  BeneficiaryDto,
  CustomFormType,
  ServiceRecordType,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";

@Component({
  name: "internalProjectBeneficiaryServiceRecordList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
  },
})
export default class InternalProjectBeneficiaryServiceRecordList extends Vue {
  dataId!: string;
  formId!: string;
  projectId!: string;
  type!: string;
  types = ServiceRecordType;
  queryForm = {
    projectId: 0,
    beneficiaryName: "",
    schoolName: "",
    serviceRecordType: "",
  };

  created() {
    if (this.$route.query.projectId) {
      this.projectId = String(this.$route.query.projectId);
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
    if (this.$route.query.type) {
      this.queryForm.serviceRecordType = this.type = String(
        this.$route.query.type
      );
    }
    if (this.$route.query.formId) {
      this.formId = String(this.$route.query.formId);
    }
    if (this.$route.query.id) {
      this.dataId = String(this.$route.query.id);
    }
  }

  fetchData(params: any) {
    if (this.$route.query.projectId) {
      params.projectId = Number(this.$route.query.projectId);
    }
    if (this.$route.query.type) {
      params.serviceRecordType = this.type = String(this.$route.query.type);
    }
    return api.beneficiaryServiceRecord.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.index, $row.item);
        break;
      case "delete":
        this.handleDelete($row.index, $row.item);
        break;
      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    let name = "internalProjectBeneficiaryServiceRecordCreate";
    if (this.type === "School") {
      name = "internalProjectSchoolServiceRecordCreate";
    }

    this.$router.push({
      name: name,
      query: {
        formId: this.formId,
        projectId: this.projectId,
      },
    });
  }

  handleEdit(index: number, row: any) {
    let name = "internalProjectBeneficiaryServiceRecordEdit";
    if (this.type === "School") {
      name = "internalProjectSchoolServiceRecordEdit";
    }
    this.$router.push({
      name: name,
      query: {
        formId: this.formId,
        projectId: this.projectId,
        id: row.id,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "internalProjectSchoolServiceRecordDetail",
      query: {
        id: row.id,
        formId: this.formId,
        type: this.type,
      },
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ServiceRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: BeneficiaryDto) {
    // await api.beneficiaryServiceRecord
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  // 删除
  async handleDelete(index: number, row: BeneficiaryDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.projectBeneficiaryMapping.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  cancel() {
    this.$router.back();
  }
}
